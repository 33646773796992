<template>
  <v-container fluid>
    <v-card max-width="900" class="mx-auto mt-4">
      <v-card-title>Gestión de eventos</v-card-title>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12" md="6" class="pa-4">
          <v-img
            class="shrink mx-auto"
            contain
            transition="scale-transition"
            src="@/assets/grupomas.png"
            max-width="350"
          ></v-img>
        </v-col>
        <v-col cols="12" md="6" class="d-flex flex-column">
          <v-form
            ref="form"
            @submit.prevent="login"
            class="d-flex align-center"
            style="width: 100%; height: 100%"
          >
            <div class="pa-2 flex-grow-1">
              <v-alert text type="error" v-if="error">{{error}}</v-alert>
              <v-text-field
                label="Usuario"
                filled
                hide-details="auto"
                v-model="user"
                :rules="[rules.req]"
              ></v-text-field>
              <v-text-field
                class="mt-4"
                label="Contraseña"
                type="password"
                filled
                hide-details="auto"
                v-model="pass"
                :rules="[rules.req]"
              ></v-text-field>
              <v-btn :loading="loading" type="submit" block x-large class="mt-4" color="primary"
                >Iniciar sesión</v-btn
              >
            </div>
          </v-form>
        </v-col>
      </v-row>
      <h5 style="color: gray;text-decoration: underline;cursor:pointer" @click="gotoEquipo" class="mt-2 pb-2 text-center">Clic aquí para el acceso equipo.</h5>
    </v-card>
  </v-container>
</template>

<script>
import { req } from "@/utils/validations.js";

export default {
  data() {
    return {
      user: null,
      pass: null,
      rules: { req },
      loading: false,
      error: null
    };
  },
  mounted(){
    if (localStorage.getItem("tokenExtra")) this.$router.push({ path: "/disponibilidad_equipo" })
  },
  methods: {
    gotoEquipo(){
      this.$router.push({path: "/auth/login_equipo"})
    },
    async login() {
      if (!this.$refs.form.validate()) return
      try {
        this.loading = true;
        await this.$store.dispatch("login", {
          user: this.user,
          pass: this.pass,
        });
        this.$router.push({ name: "Index" });
        this.loading = false;
      } catch (e) {
        this.error = 'Los datos no son correctos';
        this.loading = false;
      }
    },
  },
};
</script>
